import { getCollectionByHandle } from './getCollections'
import { getIdFromSourceEntryId } from './sourceEntryId'
import { queryShopifyStorefront } from '../services/shopifyStorefront'
import { STOREFRONT_SELLING_PLAN_SCHEMA } from './sellingPlans'

//#region GRAPHQL QUERIES
export const PRODUCT_METAFIELD_IDENTIFIERS = `[
  {key:"hidden", namespace: "seo"},
  {key:"includes", namespace: "my_fields"},
  {key:"includes", namespace: "custom"},
  {key:"short_description", namespace: "my_fields"},
  {key:"short_description", namespace: "custom"},
  {key:"delivery_details", namespace: "my_fields"},
  {key:"delivery_details", namespace: "custom"},
  {key:"prepaid_membership_text", namespace: "my_fields"},
  {key:"prepaid_membership_text", namespace: "custom"},
  {key:"regular_membership_text", namespace: "my_fields"},
  {key:"regular_membership_text", namespace: "custom"},
  {key:"harvest_handle", namespace: "my_fields"},
  {key:"harvest_handle", namespace: "custom"},
  {key:"subscription_group", namespace: "sitka"},
  {key:"gifted_subscription_group", namespace: "sitka"},
  {key:"selector_details", namespace: "custom"},
  {key:"servings", namespace: "custom"},
  {key:"requiresSellingPlan", namespace: "shopify"},
  {key:"sf_price", namespace: "custom"},
  {key:"s_price", namespace: "custom"},
  {key:"psws_price", namespace: "custom"},
  {key:"ps_price", namespace: "custom"},
  {key:"psal_price", namespace: "custom"},
]`

export const PRODUCT_SCHEMA = `
    id
    title
    handle
    description
    productType
    vendor
    createdAt
    updatedAt
    publishedAt
    onlineStoreUrl
    availableForSale
    tags
    metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
      id,
      namespace
      key
      value
    }
    options {
      id
      name
      values
    }
    sellingPlanGroups(first: 10) {
      nodes {
        name
        options {
          name
          values
        }
        sellingPlans(first: 10) {
          nodes {
            id
            name
            options {
              name
              value
            }
            priceAdjustments {
              adjustmentValue {
                ... on SellingPlanFixedAmountPriceAdjustment {
                  adjustmentAmount {
                    amount
                    currencyCode
                  }
                }
                ... on SellingPlanFixedPriceAdjustment {
                  price {
                    amount
                    currencyCode
                  }
                }
                ... on SellingPlanPercentagePriceAdjustment {
                  adjustmentPercentage
                }
              }
            }
          }
        }
      }
    }
    variants(first: 10) {
      edges {
        node {
          id
          sku
          title
          availableForSale
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
          weight
          weightUnit
          quantityAvailable
          selectedOptions {
            name
            value
          }
          metafields(identifiers: ${PRODUCT_METAFIELD_IDENTIFIERS}) {
            id,
            namespace
            key
            value
          }
          sellingPlanAllocations(first: 10) {
            nodes {
              sellingPlan {
                ${STOREFRONT_SELLING_PLAN_SCHEMA}
              }
            }
          }
        }
      }
    }
    images(first: 10) {
      edges {
        node {
          id
          originalSrc
          altText
        }
      }
    }
`

export const GET_PRODUCTS_BY_IDS = `
query getProductsById($ids: [ID!]!) {
  nodes(ids: $ids) {
    ... on Product {
      ${PRODUCT_SCHEMA}
    }
  }
}
`

export const GET_PRODUCT_BY_HANDLE = `
query getProductsByHandle($handle: String!) {
  product(handle: $handle) {
    ${PRODUCT_SCHEMA}
  }
}
`

//#endregion

export const getProductsById = async (ids) => {
  const uniqueIds = ids.reduce((array, id) => {
    const existing = array.find((x) => id === x)
    if (!existing) array.push(id)
    return array
  }, [])
  const variables = { ids: uniqueIds }
  const { nodes } = await queryShopifyStorefront({
    query: GET_PRODUCTS_BY_IDS,
    variables,
  })
  // TODO: move this formatting into a utils file so we don't have to copy and paste this a whole bunch of places. -- ZJ
  return nodes
    .filter((p) => !!p)
    .map((product) => ({
      ...product,
      sourceEntryId: product.id,
      sourceId: product.id,
      metafields: product.metafields.filter((el) => !!el),
      id: getIdFromSourceEntryId(product.id),
      content: {
        sourceEntryId: product.id,
        sourceId: product.id,
        id: getIdFromSourceEntryId(product.id),
        handle: product.handle,
        title: product.title,
        description: product.description,
        metafields: product.metafields.filter((el) => !!el),
        options: product.options,
        featuredMedia: {
          id: product.images.edges[0]?.node?.id,
          src: product.images.edges[0]?.node?.originalSrc,
          altText: product.images.edges[0]?.node?.altText,
          thumbnailSrc:
            product.images.edges[0]?.node?.originalSrc + '&width=100',
          type: 'IMAGE',
        },
        media: product.images.edges
          .filter(({ node }) => !!node)
          .map(({ node }) => ({
            id: node.id,
            src: node.originalSrc,
            altText: node.altText,
            thumbnailSrc: node.originalSrc + '&width=100',
          })),
      },
      variants: product.variants.edges
        .filter(({ node }) => !!node)
        .map(({ node }) => ({
          ...node,
          sourceEntryId: node.id,
          id: getIdFromSourceEntryId(node.id),
          sku: node.sku,
          price: node.price?.amount,
          compareAtPrice: node?.compareAtPrice?.amount,
          productHandle: product.handle,
          metafields: node.metafields.filter((el) => !!el),
          content: {
            title: node.title,
            variantEntryId: node.id,
            selectedOptions: node.selectedOptions,
          },
          featuredMedia: {
            src: product.images.edges[0]?.node?.originalSrc,
            altText: product.images.edges[0]?.node?.altText,
            thumbnailSrc:
              product.images.edges[0]?.node?.originalSrc + '&width=100',
          },
          sellingPlans: node?.sellingPlanAllocations?.nodes?.map(
            ({ sellingPlan }) => sellingPlan,
          ),
        })),
    }))
}

export const getProductByHandle = async (handle) => {
  const { product } = await queryShopifyStorefront({
    query: GET_PRODUCT_BY_HANDLE,
    variables: { handle },
  })

  if (!product) {
    return null
  }
  return {
    ...product,
    sourceEntryId: product.id,
    sourceId: product.id,
    metafields: product.metafields.filter((el) => !!el),
    content: {
      sourceEntryId: product.id,
      sourceId: product.id,
      handle: product.handle,
      title: product.title,
      description: product.description,
      metafields: product.metafields.filter((el) => !!el),
      options: product.options,
      featuredMedia: {
        id: product.images.edges[0]?.node?.id || null,
        src: product.images.edges[0]?.node?.originalSrc || '',
        altText: product.images.edges[0]?.node?.altText || '',
        thumbnailSrc: product.images.edges[0]?.node?.originalSrc + '&width=100',
        type: 'IMAGE',
      },
      media: product.images.edges.map(({ node }) => ({
        id: node.id,
        src: node.originalSrc,
        altText: node.altText || '',
        thumbnailSrc: node.originalSrc + '&width=100',
      })),
    },
    variants: product.variants.edges.map(({ node }) => ({
      ...node,
      sourceEntryId: node.id,
      sku: node.sku,
      availableForSale: node.availableForSale,
      price: +node.price?.amount,
      compareAtPrice: node?.compareAtPrice?.amount || 0,
      weight: node.weight,
      weightUnit: node.weightUnit,
      productHandle: product.handle,
      quantityAvailable: node.quantityAvailable,
      metafields: node.metafields.filter((el) => !!el),
      content: {
        title: node.title,
        variantEntryId: node.id,
        selectedOptions: node.selectedOptions,
      },
      featuredMedia: {
        src: product.images.edges[0]?.node?.originalSrc || '',
        altText: product.images.edges[0]?.node?.altText || '',
        thumbnailSrc: product.images.edges[0]?.node?.originalSrc + '&width=100',
      },
      sellingPlans: node?.sellingPlanAllocations?.nodes?.map(
        ({ sellingPlan }) => sellingPlan,
      ),
    })),
  }
}

export const getProductsByHandles = async (handles) => {
  const fetches = handles.productHandles.map((handle) =>
    getProductByHandle(handle),
  )
  const products = await Promise.all(fetches)
  return products.filter((el) => el)
}

export const getAvailableAddons = async () => {
  const { products } = await getCollectionByHandle('addons')
  return products.map((p) => ({
    ...p,
    id: getIdFromSourceEntryId(p.sourceEntryId),
  }))
}

export const getAvailableSwaps = async () => {
  const { products } = await getCollectionByHandle('swapables')
  return products.map((p) => ({
    ...p,
    id: getIdFromSourceEntryId(p.sourceEntryId),
  }))
}

export const getAvailableBabProducts = async () => {
  const { products } = await getCollectionByHandle('subscribable')
  return products.map((p) => ({
    ...p,
    id: getIdFromSourceEntryId(p.sourceEntryId),
  }))
}

export const getAllProducts = async () => {
  const query = `
    {
      products(first: 250) {
        edges {
          node {
            id
            handle
            tags
          }
        }
      }
    }
  `

  const res = await fetch(
    `https://${process.env.NEXT_PUBLIC_MYSHOPIFY_DOMAIN}/api/${process.env.NEXT_PUBLIC_STOREFRONT_API_VERSION}/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN,
      },
      body: JSON.stringify({ query }),
    },
  )
  const resp = await res.json()
  const products = resp.data.products.edges.map(({ node }) => node)
  return products
}
